import { type IAction } from '../../../types/commonTypes';
import { type IRecommendationWidget, type IRecommendationContextValue, type IWidgetPayload } from '../../../types/recommendationTypes';
import RecommendationWidgetActionTypes from './RecommendationWidgetActionTypes';
import RecommendationWidgetInitialState from './RecommendationWidgetInitialState';

export const RecommendationWidgetReducer = (state = RecommendationWidgetInitialState, action: IAction | IWidgetPayload): IRecommendationContextValue => {
  switch (action.type) {
    case RecommendationWidgetActionTypes.SET_ROWS:
      return {
        ...state,
        rows: Number(action.payload)
      };
    case RecommendationWidgetActionTypes.SET_START:
      return {
        ...state,
        start: Number(action.payload)
      };
    case RecommendationWidgetActionTypes.SET_WIDGET:
      return {
        ...state,
        widget: action.payload as IRecommendationWidget
      };
    default:
      return state;
  }
};

export default RecommendationWidgetReducer;
