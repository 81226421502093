import React, { Suspense } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Spinner } from './components/Common/Spinner';

// Lazy load the app whilst the display filters are being set up.
const LazyLayout = React.lazy(async () => await import('./components/RecommendationWidgets/RecommendationLayout').then(({ RecommendationLayout }) => ({ default: RecommendationLayout })));

const RecommendationApp: React.FC = () => (
  <>
    <Suspense fallback={<Spinner />}>
      <LazyLayout />
    </Suspense>
    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
  </>
);

export default RecommendationApp;
