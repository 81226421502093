import RecommendationConfigActionTypes from './RecommendationConfigActionTypes';
import { type IAction } from '../../../types/commonTypes';
import RecommendationConfigInitialState from './RecommendationConfigInitialState';
import { type IRecommendationConfigContextValue } from '../../../types/recommendationTypes';

export const RecommendationConfigReducer = (state = RecommendationConfigInitialState, action: IAction): IRecommendationConfigContextValue => {
  switch (action.type) {
    case RecommendationConfigActionTypes.SET_RECOMMENDATION_RESULTS_LOADED:
      return {
        ...state,
        recommendation_results_loaded: Boolean(action.payload)
      };

    case RecommendationConfigActionTypes.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        current_language: action.payload.toString()
      };

    default:
      return state;
  }
};

export default RecommendationConfigReducer;
