import numeral from 'numeral';

/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
numeral.register('locale', 'en-au', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal (number) {
    const b = number % 10;
    return (~~(number % 100 / 10) === 1)
      ? 'th'
      : (b === 1)
          ? 'st'
          : (b === 2)
              ? 'nd'
              : (b === 3) ? 'rd' : 'th';
  },
  currency: {
    symbol: 'A$'
  }
});
