import Cookies from 'js-cookie';

// Bloomreach recommends to generate unique, random values of 13 digits.
const getRequestId = (): number => {
  const min = 1000000000000;
  const max = 9999999999999;
  const requestId = Math.floor(Math.random() * (max - min + 1)) + min;

  return requestId;
};

const RecommendationWidgetInitialState = {
  fields: 'pid,type,phx_id,title,thumb_image,short_description,no_of_reviews,review_rating,category_1',
  start: 0,
  rows: 12,
  account_id: (process.env.REACT_APP_ACCOUNT_ID ?? ''),
  domain_key: (process.env.REACT_APP_DOMAIN_KEY ?? ''),
  url: document.location.href,
  _br_uid_2: Cookies.get('_br_uid_2') ?? '123',
  request_id: getRequestId(),
  view_id: (process.env.REACT_APP_SEARCH_VIEW_ID ?? ''),
  widget: { widget_id: '', widget_title: '' }
};

export default RecommendationWidgetInitialState;
