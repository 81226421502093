import { type IQueryConfig } from '../types/appConfigTypes';

export const isEmpty = (variable: object | string[] | string | number | boolean | undefined): boolean => {
  const isEmpty = false;

  if (typeof variable === 'undefined' || variable === '' || variable === null || variable === 0) {
    return true;
  }

  if (typeof variable === 'object' && Object.keys(variable).length <= 0) {
    return true;
  }

  return isEmpty;
};

/** Function to add some default config settings to each query */
export const getDefaultConfig = (): IQueryConfig => {
  const queryConfig = {
    refetchOnWindowFocus: true,
    // Set to 5 minutes.
    staleTime: 60 * 1000 * 5,
    // Set to 10 minutes.
    cacheTime: 60 * 1000 * 10
  };

  return queryConfig;
};

export const formatDate = (date: string): string => {
  if (isEmpty(date)) {
    return '';
  }
  /* Date converted to MM/DD/YYYY format */
  const dateObj = new Date(Number(date) * 1000);
  const dateMDY = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;

  return dateMDY;
};
