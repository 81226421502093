import { isEmpty } from './utilities';
import { type IRecommendationWidgetResponse, type IRecommendationWidgetResult, type IRecommendationConfigContextValue } from '../types/recommendationTypes';
import RecommendationConfigInitialState from '../reducers/Recommendation/RecommendationConfigReducer/RecommendationConfigInitialState';

/** Process search results to get useful information. */
export const getRecommendationResultsList = (results: IRecommendationWidgetResponse): IRecommendationWidgetResult[] => {
  let data: IRecommendationWidgetResult[] = [];
  if (
    !isEmpty(results) &&
    !isEmpty(results.response) &&
    !isEmpty(results.response.docs)
  ) {
    data = results.response.docs;
  }

  return data;
};

/** Process search results to get useful information. */
export const getRecommendationWidgetMetadata = (results: IRecommendationWidgetResponse): object => {
  let data: object = {};
  if (
    !isEmpty(results) &&
    !isEmpty(results.metadata) &&
    !isEmpty(results.metadata.widget)
  ) {
    data = results.metadata.widget;
  }

  return data;
};

/** Get the recommendation config. */
export const getRecommendationConfig = (): IRecommendationConfigContextValue => {
  // Get the site config from one of the expected locations.
  // For Drupal websites, this will be the Drupal settings.
  let recommendationConfig = RecommendationConfigInitialState;

  if (
    typeof window !== 'undefined' &&
    typeof window.drupalSettings !== 'undefined' &&
    !isEmpty(window.drupalSettings.recommendation_widget)
  ) {
    recommendationConfig = window.drupalSettings.recommendation_widget;
  }

  return recommendationConfig;
};

/** Get the recommendation API Base Url from the the app config. */
export const getRecommendationApiBaseUrl = (): string => {
  let recommendationApiBaseUrl = '';
  const appConfig = getRecommendationConfig();
  if (
    typeof appConfig !== 'undefined' &&
    typeof appConfig.recommendation_api_base_url !== 'undefined'
  ) {
    recommendationApiBaseUrl = appConfig.recommendation_api_base_url;
  }

  return recommendationApiBaseUrl;
};
