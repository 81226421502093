import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import './translations/i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecommendationWidgetProvider } from './providers/Recommendation/RecommendationWidgetProvider';
import RecommendationApp from './RecommendationApp';
import { RecommendationConfigProvider } from './providers/Recommendation/RecommendationConfigProvider/RecommendationConfigProvider';
import { getRecommendationConfig } from './utils/recommendation';
import { type IRecommendationWidget } from './types/recommendationTypes';

// Initialise React Query Client.
const queryClient = new QueryClient();

// Recommendation Widgets.
const { widgets } = getRecommendationConfig();

widgets.forEach((widget: IRecommendationWidget) => {
  const widgetId = 'search-recommendation-widget-' + widget.widget_id;

  if (document.getElementById(widgetId) !== null) {
    // Render product recommendation app.
    const recommendationRoot = ReactDOM.createRoot(
      document.getElementById(widgetId) as HTMLElement
    );
    recommendationRoot.render(
      <React.StrictMode>
        <I18nextProvider i18n={i18next}>
          <QueryClientProvider client={queryClient}>
            <RecommendationConfigProvider>
              <RecommendationWidgetProvider widget={widget}>
                <RecommendationApp/>
              </RecommendationWidgetProvider>
            </RecommendationConfigProvider>
          </QueryClientProvider>
        </I18nextProvider>
      </React.StrictMode>
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
