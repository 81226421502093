import React, {
  useMemo,
  useEffect,
  useReducer
} from 'react';
// Default currency $.
import numeral from 'numeral';
// Add £.
import 'numeral/locales/en-gb';
// Add €.
import '../../../locales/numeral/en-ie';
// Add A$.
import '../../../locales/numeral/en-au';
import { node } from 'prop-types';
import { isEmpty } from '../../../utils/utilities';
import { RecommendationWidgetReducer } from '../../../reducers/Recommendation/RecommendationWidgetReducer';
import { getRecommendationWidgetParams } from '../../../utils/searchQuery';
import { RecommendationWidgetContext } from '../../../contexts/Recommendation/RecommendationWidgetContext';
import { useRecommendationConfigContext } from '../../../hooks/Recommendation/useRecommendationConfigContext';
import { type IRecommendationWidget } from '../../../types/recommendationTypes';
import RecommendationWidgetActionTypes from '../../../reducers/Recommendation/RecommendationWidgetReducer/RecommendationWidgetActionTypes';

interface IProps {
  children: React.ReactNode
  widget: IRecommendationWidget
}

export const RecommendationWidgetProvider: React.FC<IProps> = ({ children, widget }) => {
  // Get the site config from one of the expected locations
  // and configure the product recommendation. For Drupal websites, this
  // will be the Drupal settings.
  const initialRecommendationParams = getRecommendationWidgetParams();

  // Get the app config state.
  const {
    recommendationConfig
  } = useRecommendationConfigContext();

  // Store the current product recommendation parameters in state.
  const [recommendationParams, setRecommendationParams] = useReducer(
    RecommendationWidgetReducer,
    initialRecommendationParams
  );

  // When the lang changes (initial selection), we need to update the related states.
  useEffect(() => {
    // Update the currency formatting.
    let numeralLocale = '';
    if (recommendationConfig.current_language === 'en') {
      numeralLocale = 'en-gb';
    } else if (recommendationConfig.current_language === 'de-de' ||
      recommendationConfig.current_language === 'en-ie' ||
      recommendationConfig.current_language === 'en-nl') {
      numeralLocale = 'en-ie';
    } else if (recommendationConfig.current_language === 'en-au') {
      numeralLocale = 'en-au';
    }
    if (numeralLocale.length > 0) {
      numeral.locale(numeralLocale);
    } else {
      numeral.reset();
    }
  }, [recommendationConfig.current_language]);

  // Set widgets details.
  useEffect(() => {
    if (!isEmpty(recommendationParams.widget)) {
      setRecommendationParams({
        type: RecommendationWidgetActionTypes.SET_WIDGET,
        payload: widget
      });
    }
  }, [recommendationParams.widget]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const RecommendationContextValue = useMemo(() => ({
    recommendationParams,
    setRecommendationParams
  }), [recommendationParams]);

  return (
    <RecommendationWidgetContext.Provider value={RecommendationContextValue}>{children}</RecommendationWidgetContext.Provider>
  );
};

RecommendationWidgetProvider.propTypes = {
  children: node.isRequired
};

RecommendationWidgetProvider.defaultProps = {};

export default RecommendationWidgetProvider;
