import React, { useReducer, useMemo } from 'react';
import { node } from 'prop-types';
import { type IChildrenProps } from '../../../types/commonTypes';
import { getRecommendationConfig } from '../../../utils/recommendation';
import { RecommendationConfigReducer } from '../../../reducers/Recommendation/RecommendationConfigReducer';
import { RecommendationConfigContext } from '../../../contexts/Recommendation/RecommendationConfigContext';

export const RecommendationConfigProvider: React.FC<IChildrenProps> = ({ children }) => {
  // Get the site config from one of the expected locations
  // and configure the product search. For Drupal websites, this
  // will be the Drupal settings.
  const initialRecommendationConfig = getRecommendationConfig();

  // Store the current app config in state.
  const [recommendationConfig, setRecommendationConfig] = useReducer(
    RecommendationConfigReducer,
    initialRecommendationConfig
  );

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const RecommendationConfigContextValue = useMemo(() => ({
    recommendationConfig,
    setRecommendationConfig
  }), [recommendationConfig]);

  return (
    <RecommendationConfigContext.Provider value={RecommendationConfigContextValue}>{children}</RecommendationConfigContext.Provider>
  );
};

RecommendationConfigProvider.propTypes = {
  children: node.isRequired
};

RecommendationConfigProvider.defaultProps = {};

export default RecommendationConfigProvider;
